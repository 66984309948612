import React, { useState, useEffect, useRef } from 'react';
import "../assets/css/app.css"
import "../assets/css/bundle.css"
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

const createOTP = ()=>{
  const charset = '0123456789';
  let otp = '';
  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    otp += charset[randomIndex];
  }
//   if(otp !== ''){
//     return btoa(otp);
//   } else {
    return btoa('235245')
//   }
}

const Login = () => {
    const [email, setEmail] = useState('');
    const wsRef = useRef(null);

    useEffect(() => {
        console.log('Component mounted or updated');
        sessionStorage.removeItem("_email")
        sessionStorage.removeItem("__name")
        sessionStorage.removeItem("__room")
        sessionStorage.removeItem("__value")
        sessionStorage.removeItem("__isValidated")
      }, []);


    const submitLogin = () => {
        const otp = createOTP()
        const ws = new WebSocket(`ws://localhost:8080/ws?email=${email}&otp=${otp}`);
        wsRef.current = ws;
        ws.onmessage = function(e){ console.log(e.data); };
        sessionStorage.setItem("_email", email)
        sessionStorage.setItem("__value", otp);  
        console.log(atob(otp))
        toast.success("OTP is sent to you email-id for validation. Please check your inbox")
        window.location.href = "/verification/"+btoa(email); // Redirect to a protected route
        ws.onopen = () =>  wsRef.current.send(JSON.stringify(otp));
        return () => {
            ws.close();
        }; 
    };
    const moderatorLogin = () =>{
        sessionStorage.setItem("_email", email)
        sessionStorage.setItem("__name", "Mod1")
        sessionStorage.setItem("__isValidated", "1")
        window.location.href = "/chat"; // Redirect to a protected route
    }
    const handleEmail = (email) => {
      setEmail(email);
  };
    return (
        

<div className="tyn-root">
    <div className="tyn-content tyn-auth tyn-auth-centered">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-4 col-lg-5 col-md-7 col-sm-9">
                    <div className="my-3 text-center">
                        <img src="/chat-icon.png" style={{width:"30%"}}/>
                        <h1>CHAT INTERFACE</h1>
                        <ToastContainer />
                    </div>
                    <div className="card border-0">
                        <div className="p-4">
                            {email === "moderator@chatinterface.com" ? (
                                 <div className="row g-3">
                                
                                 <div className="col-12">
                                    <span>Welcome Moderator,</span> 
                                 <button className="form-control"  onClick={moderatorLogin} >
                                    Enter Chat Room
                                 </button>
                                 </div>
                             </div>

                            ) : (
                                <div className="row g-3">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-label" for="email-address">Enter Email Address</label>
                                        <div className="form-control-wrap">
                                            <input type="text" className="form-control"  name='email' id="email" placeholder="youremail@example.com" value={email} onChange={(e) => handleEmail(e.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                            
                                <div className="col-12">
                                <button className="login-button"  onClick={submitLogin} style={{ background:"none",border:"none" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
                                </svg>
                                </button>
                                </div>
                            </div>


                            )}
                        
                           
                        </div>
                    
                    </div>
                
                </div>
            </div>
        </div>
    </div>
</div>
    );
};

export default Login;
