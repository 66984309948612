import React, { useState, useEffect, useRef } from 'react';
import "../assets/css/app.css"
import "../assets/css/bundle.css"
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

const createOTP = ()=>{
  const charset = '0123456789';
  let otp = '';
  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    otp += charset[randomIndex];
  }
  if(otp !== ''){
    return btoa(otp);
  } else {
    return btoa('235245')
  }
}

const getNum = () => {
  const numChar = '123456789';
  let num = '';
  for (let i = 0; i < 2; i++) {
    const randomNum = Math.floor(Math.random() * numChar.length);
    num += numChar[randomNum];
  }
  if(num != '') {
    return num
  } else {
    return 12
  }
};

const Verification = () => {
    const [otp, setOTP] = useState('');
    const [name, setName] = useState('');
    const wsRef = useRef(null);
    console.log(otp)
    useEffect(() => {
      console.log('Component mounted or updated');
     const isEmail =  sessionStorage.getItem("_email")
     const isValue = sessionStorage.getItem("__value")
     if(isEmail == null && isValue == null){
      toast.error("Account is not valid")
      window.location.href = "/"; // Redirect to a protected route
     }
    }, []);
    const submitLogin = () => {
      const getOTP = sessionStorage.getItem("__value");  
      console.log(otp)
      if(atob(getOTP) === otp){
        toast.success("OTP is correct. Go to chat section")
        const maskedName = "C-"+getNum()
        sessionStorage.setItem("__name", maskedName)
        sessionStorage.setItem("__isValidated", "1")
        console.log("Check link")
        window.location.href = "/chat"; // Redirect to a protected route
      } else {
        toast.error("Please enter correct OTP")
      }
    }

    const resendOTP = () =>{
        const otp = createOTP()
        const getEmail = sessionStorage.getItem("_email");  
        const ws = new WebSocket(`ws://localhost:8080/ws?email=${getEmail}&otp=${otp}`);
        wsRef.current = ws;
        ws.onmessage = function(e){ console.log(e.data); };
        sessionStorage.removeItem("__value");  
        sessionStorage.setItem("__value", otp);  
        console.log(atob(otp))
        toast.success("OTP is sent to you email-id for validation. Please check your inbox")
        
        ws.onopen = () =>  wsRef.current.send(JSON.stringify(otp));
        return () => {
            ws.close();
        };
       
        
        
    };
    const handleOTP = (otp) => {
      setOTP(otp);
  };
  const handleNameChange = (newName) => {
      setName(newName);
  };
    return (
        

<div className="tyn-root">
    <div className="tyn-content tyn-auth tyn-auth-centered">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-4 col-lg-5 col-md-7 col-sm-9">
                    <div className="my-3 text-center">
                        <img src="/chat-icon.png" style={{width:"30%"}}/>
                        <h1>CHAT INTERFACE</h1>
                        <ToastContainer />
                    </div>
                    <div className="card border-0">
                        <div className="p-4">
                        
                            <div className="row g-3">
                                <div className="col-12">
                                    <div className="form-group">
                                        {/* <label className="form-label" for="otp">Enter the OTP</label> */}
                                        <div className="form-control-wrap" style={{ marginBottom:"4%" }}>
                                                    <input type="text" className="form-control" placeholder="Enter your name"  name='name' id="name"  value={name} onChange={(e) => handleNameChange(e.target.value)}/>
                                                </div>
                                        <div className="form-control-wrap">
                                            <input type="text" className="form-control" placeholder="Enter OTP"  name='otp' id="otp"  value={otp} onChange={(e) => handleOTP(e.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                            
                                <div className="col-12">
                                
                                <button className="login-button"  onClick={resendOTP} style={{ background:"none",border:"none",marginRight:"59%" }}>Resend OTP</button>
                                <button className="login-button"  onClick={submitLogin} style={{ background:"none",border:"none" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
                                </svg>
                                </button>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                
                </div>
            </div>
        </div>
    </div>
</div>
    );
};

export default Verification;
