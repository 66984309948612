import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';


import Login from './Components/Login';
import Verification from './Components/Verification';
import Chat from './Components/Chatroom/Chat';


function App() {
  return (

        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/verification/:id" element={<Verification />} />
            <Route path="/chat" element={<Chat />} />
          </Routes>
        </BrowserRouter>
      
  );
}

export default App;
